import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.eventApiBaseUrl}events`;
export default {
  fetchAllEventsByEventDefinitionId(id, query) {
    const url = `${environment.eventApiBaseUrl}event-definition/${id}/events` + query;
    return httpClient.get(url)
      .then(res => res.data.data.events)
      .catch(error => console.log(error));
  },
  fetchAllEvents() {
    return httpClient.get(URL)
      .then(res => res.data.data)
      .catch(error => console.log(error));
  },
  storeEvent(event) {
    return httpClient.post(URL, event)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
  updateEvent(event, id) {
    const url = URL + `/${id}?_method=PUT`;
    return httpClient.post(url, event)
      .then(res => res.data)
      .catch(error => console.log(error));
  },

  deleteEvent(ids) {
    return httpClient.delete(`${URL}/${ids}`)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
  exportEventsToExcel() {
    const url = URL + '/export';
    return httpClient.get(url, {responseType: 'arraybuffer'})
      .then(res => {
        let fileURL = window.URL.createObjectURL(new Blob([res.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'events.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => console.log(error));
  },
  fetchStatistics(id){
    const url = URL +`/${id}/statistics`;
    return httpClient.get(url)
      .then(res => res.data.data)
      .catch(error => console.log(error));
  },
};
