import eventsDefinitionRepository from "./eventsDefinitionRepository";
import eventUsersRepository from "./eventUsersRepository";
import eventsRepository from "./eventsRepository";

export const repositories = {
  eventsDefinitionRepository,
  eventUsersRepository,
  eventsRepository

};

export const RepositoryFactory = {
  get: name => repositories[name]
};
