<template>
  <div>
    <vs-card>
      <vs-row
        class="mt-5 mb-5"
        vs-justify="space-between"
        vs-type="flex"
      >
        <vs-col
          vs-align="right"
          vs-justify="center"
          vs-type="flex"
          vs-w="3"
        >
          <h5>
            <vs-chip color="primary">
              المعرف : {{ this.$route.params.id }}
            </vs-chip>
          </h5>
        </vs-col>
        <vs-col
          vs-align="left"
          vs-justify="center"
          vs-type="flex"
          vs-w="3"
        >
          <h5>
            <vs-chip color="primary">
              يوم الحفلة : {{ this.$route.params.name }}
            </vs-chip>
          </h5>
        </vs-col>
      </vs-row>
    </vs-card>
    <ag-table
      :data-source-api="getAll"
      :grid-options="gridOptions"
      :permissions="permissions"
      :rtl="false"
      @emitAddNewRecord="createRecord()"
      @emitDeleteMultipleRecords="deleteEvent($event, null , true)"
      @emitExportToExcel="exportToExcel"
    />
    <tm-popup
      :submit="onSubmit"
      :title="dialogTitle"
      ref="popup"
    >
      <div class="vx-row justify-center">
        <div class="vx-col w-full">
          <tm-input
            class="w-full"
            name="يوم الحفلة"
            placeholder="يوم الحفلة"
            type="text"
            v-model="event.name"
            validation-rules="required"
          />
        </div>
      </div>
      <div class="vx-row justify-center mt-3">
        <div class="vx-col w-full">
          <tm-input
            class="w-full"
            name="تاريخ الحفلة"
            type="date"
            v-model="event.date"
            validation-rules="required"
          />
        </div>
      </div>
      <div class="vx-row justify-center mt-3">
        <div class="vx-col w-full">
          <tm-input
            class="w-full"
            name="من الساعة"
            type="time"
            v-model="event.from_time"
            validation-rules="required"
          />
        </div>
      </div>
      <div class="vx-row justify-center mt-3">
        <div class="vx-col w-full">
          <tm-input
            class="w-full"
            name="إلى الساعة"
            type="time"
            v-model="event.to_time"
            validation-rules="required"
          />
        </div>
      </div>
      <div class="vx-row justify-center mt-3">
        <div class="vx-col w-full">
          <tm-input
            class="w-full"
            name="السعة"
            placeholder="السعة"
            type="number"
            v-model="event.capacity"
            validation-rules="required"
          />
        </div>
      </div>
      <div class="vx-row justify-center mt-3">
        <div class="vx-col w-full">
          <vs-checkbox
            class="inline-flex "
            style="font-size:medium;"
            v-model="event.is_active"
          >
            مفعل
          </vs-checkbox>
        </div>
      </div>
      <div class="vx-row justify-center mt-3">
        <div class="vx-col w-full">
          <vs-checkbox
            class="inline-flex "
            style="font-size:medium;"
            v-model="event.is_public"
          >
            عام
          </vs-checkbox>
        </div>
      </div>
    </tm-popup>
  </div>
</template>

<script>
  import {RepositoryFactory} from '../event-repositories/eventRepositoryFactory';
  import AgTable from '../../shared/shared-components/ag-grid/AgTableServerSide.vue';
  import utilities from "@/app/shared/utilities";
  import AgTableBtnCell from "../../shared/shared-components/ag-grid/AgTableBtnCell";
  import AgTableIsActiveIcon from "@/app/shared/shared-components/ag-grid/AgTableIsActiveIcon";
  import TmPopup from "@/app/shared/shared-components/TmPopup";
  import TmInput from "@/app/shared/shared-components/TmInput";
  import AgButton from "@/app/shared/shared-components/ag-grid/AgButton";

  const eventsRepo = RepositoryFactory.get("eventsRepository");
  export default {
    name: "Events",
    components: {
      AgTable,
      TmPopup,
      TmInput
    },
    data() {
      return {
        gridApi: null,
        dialogTitle: '',
        event: {
          id: '',
          name: '',
          type: '',
          event_definition_id: '',
          date: '',
          from_time: '',
          to_time: '',
          is_active: '',
          is_public:'',
          capacity: ''
        },
        gridOptions: null,
        permissions: {
          add: 'store_event',
          delete: 'destroy_event',
          edit: 'update_event'
        }
      };
    },
    methods: {
      deleteEvent(ids, index, isMultiple = false) {
        if (isMultiple)
          ids = ids.join(',');
        eventsRepo.deleteEvent(ids).then((response) => {
          if (response.code === 200) {
            this.gridApi.refreshServerSideStore({purge: true});
          }
        });
      },
      getAll(query) {
        return eventsRepo.fetchAllEventsByEventDefinitionId(this.$route.params.id, query).then((response => response));
      },
      createColDefs() {
        let self = this;
        return [
          {
            headerName: 'المعرف',
            field: 'id',
            suppressSizeToFit: true,
            checkboxSelection: true,
            filter: 'agNumberColumnFilter',
            width: 100
          },
          {
            headerName: 'يوم الحفلة',
            field: 'name',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'تاريخ الحفلة',
            field: 'date',
            filter: 'agDateColumnFilter',
            width: 200
          },
          {
            headerName: 'من',
            field: 'from_time',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'إلى',
            field: 'to_time',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'السعة',
            field: 'capacity',
            filter: 'agNumberColumnFilter',
          },
          {
            headerName: 'السعة المتبقية',
            field: 'rest_capacity',
            filter: 'agNumberColumnFilter',
          },
          {
            headerName: 'مفعل',
            field: 'is_active',
            cellRendererFramework: AgTableIsActiveIcon,
            cellRendererParams: {
              isActiveIcon: 'CheckCircleIcon',
              notActiveIcon: 'XCircleIcon'
            },
            width: 100
          },
          {
            headerName: 'عام',
            field: 'is_public',
            cellRendererFramework: AgTableIsActiveIcon,
            cellRendererParams: {
              isActiveIcon: 'CheckCircleIcon',
              notActiveIcon: 'XCircleIcon'
            },
            width: 100
          },
          {
            headerName: 'الإحصائيات',
            cellRendererFramework: AgButton,
            valueGetter:
              function getObject(params) {
                return params.data.id;
              },
            cellRendererParams: {
              size: 'small',
              color: 'primary',
              name: 'الإحصائيات',
              type: 'gradient',
              permission: 'get_events',
              click(valueGetter) {
                self.statisticsClicked(valueGetter);
              }
            },
            sortable: false,
            width: 100
          },
          {
            headerName: 'الإجراءات',
            valueGetter:
              function getObject(params) {
                return params.data.id;
              },
            cellRendererFramework: AgTableBtnCell,
            cellRendererParams: {
              deleteRecord: function (valueGetter, rowIndex) {
                self.deleteEvent(valueGetter, rowIndex);
              },
              editRecord: function (record) {
                self.editRecord(record);
              },
              actions: ['edit', 'delete'],
              permissions: self.permissions
            },
            sortable: false,
            width: 100
          },
        ];
      },
      createRecord() {
        utilities.initObjectMembers(this.event);
        this.dialogTitle = 'إضافة يوم للحفلة';
        this.openPopup();
      },
      openPopup() {
        this.$refs.popup.open();
      },
      editRecord(event) {
        this.dialogTitle = 'تعديل يوم الحفلة';
        Object.assign(this.event, event);
        this.openPopup();
      },
      statisticsClicked(id) {
        this.$router.push(`/dailyStatistics/${id}`);
      },
      onSubmit() {
        if (this.event.is_active === null)
          this.event.is_active = false;
        if (this.event.is_public === null)
          this.event.is_public = false;
        this.event.event_definition_id = this.$route.params.id;
        this.event.type = 'LIMITED';
        if (this.event.id !== null) {
          this.updateEvent(this.event);
        } else {
          this.storeEvent(this.event);
        }
      },
      storeEvent(event) {
        eventsRepo.storeEvent(this.$objectToFD(event)).then((response) => {
          this.$refs.popup.endLoading();
          if (response.code === 200) {
            this.$refs.popup.close();
            this.gridApi.refreshServerSideStore({purge: true});
          }
        });
      },
      updateEvent(event) {
        eventsRepo.updateEvent(this.$objectToFD(event), event.id).then((response) => {
          this.$refs.popup.endLoading();
          if (response.code === 200) {
            this.$refs.popup.close();
            this.gridApi.refreshServerSideStore({purge: true});
          }
        });
      },
      exportToExcel() {
        eventsRepo.exportEventsToExcel();
      }
    },
    created() {
      this.gridOptions = {};
      this.gridOptions.columnDefs = this.createColDefs();
    },
    mounted() {
      this.gridApi = this.gridOptions.api;
    }
  };
</script>
