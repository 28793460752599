import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.eventApiBaseUrl}event-users`;
export default {
  fetchEventUsersByEventDefinitionId(id, query) {
    const url = `${environment.eventApiBaseUrl}event-definition/${id}/event-users` + query;
    return httpClient.get(url)
      .then(res => res.data.data.event_users)
      .catch(error => console.log(error));
  },
  storeEventUser(eventUser) {
    const url = URL + '/many';
    return httpClient.post(url, eventUser)
      .then(res => res.data)
      .catch(error => console.log(error));
  },

  updateEventUser(userEvent, id) {
    return httpClient.post(`${URL}/${id}?_method=PUT`, userEvent)
      .then(res => res.data)
      .catch(error => console.log(error));
  },

  deleteEventUser(ids) {
    return httpClient.delete(`${URL}/${ids}`)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
  changeStatus(ids, status) {
    let eventUserIds = {event_user: ids, status: status};
    const url = URL + '/update/status';
    return httpClient.post(`${url}?_method=PUT`, eventUserIds)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
  changeType(ids, type) {
    let eventUserIds = {event_user: ids, type: type};
    const url = URL + '/update/type';
    return httpClient.post(`${url}?_method=PUT`, eventUserIds)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
  exportEventUsersToExcel(eventDefinitionId) {
    const url = `${environment.eventApiBaseUrl}event-definition/${eventDefinitionId}/event-users/export`;
    return httpClient.get(url, {responseType: 'arraybuffer'})
      .then(res => {
        let fileURL = window.URL.createObjectURL(new Blob([res.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'eventUsers.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => console.log(error));
  },
  async updateEventUsersFromExcel(excelData) {
    const url = URL + '/import';
    let excelFile = new FormData();
    excelFile.append('file', excelData);
    await httpClient.post(url, excelFile);
  },
};
